export default function getActivityTextStatus(item, type) {
  if (!item.schedule?.link) {
    return 'n/a'
  }

  if (item.sendStatus !== 3) {
    return '-'
  }

  switch (type) {
    case 'pageOpened':
      return item.pageOpened || 0
    case 'started':
      return item.activityStarted ? 'Yes' : 'No'
    case 'progress':
      return item.activityProgress
    case 'complete':
      return item.activityProgress >= 80 ? 'Yes' : 'No'
    default:
      return ''
  }
}
