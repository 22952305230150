<template>
  <div>
    <v-data-table
      item-key="id"
      :headers="headers"
      :items-per-page="perPage"
      :server-items-length="total"
      :items="items"
      :loading="loading"
      class="elevation-1"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50, 100]
      }"
      @update:page="loadItems"
      @update:items-per-page="pageCount"
      @click:row="handleSelect"
    >
      <template
        v-for="(_, slot) of $scopedSlots"
        v-slot:[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'FirebaseTable',
  props: {
    counterPath: {
      type: String,
      required: false,
      default: ''
    },
    counterField: {
      type: String,
      required: false,
      default: 'count'
    },
    collection: {
      type: String,
      required: true
    },
    filterOperator: {
      type: String,
      default: '=='
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    headers: {
      type: Array,
      required: true
    },
    preparedCollection: {
      type: Object,
      required: false,
      default: () => {}
    },
    sortField: {
      type: String,
      default: 'createdAt'
    },
    tableName: {
      type: String,
      required: false,
      default: 'No name'
    },
    where: {
      type: Array,
      default: () => [null, '==', null]
    }
  },
  data() {
    return {
      items: [],
      firstItems: {},
      lastItem: null,
      page: 1,
      counter: null,
      loading: true,
      perPage: 50,
    }
  },
  computed: {
    total() {
      return this.counter ? this.counter[this.counterField] : 0
    }
  },
  async created() {
    await this.$bind(
      'counter',
      this.$firebase
        .firestore()
        .doc(this.counterPath || 'counters/' + this.collection)
    )
    await this.loadItems(1)
  },
  mounted() {
    // console.log('this.headers', this.headers)
  },
  methods: {
    ...mapActions('clients', ['setClientNew']),
    async handleSelect(rowData) {
      
      this.items.map((item, index) => {
        item.selected = item === rowData
        this.$set(this.items, index, item)
      })
      if (this.tableName === 'clients') {
        const routeData = this.referer || { name: 'patientSelected' }
        await this.setClientNew(rowData)
        this.$router.push(routeData)
      }
    },
    loadItems(page) {
      this.loading = true
      const collection =
        this.preparedCollection ||
        this.$firebase.firestore().collection(this.collection)
      let query = collection
        .limit(this.perPage)
        .orderBy(this.sortField, 'desc')

      if (
        Object.keys(this.filters).length !== 0 &&
        this.filters.constructor === Object
      ) {
        Object.keys(this.filters).forEach(item => {
          query = query.where(item, '==', this.filters[item])
        })
      }

      if (page > this.page) {
        if (this.lastItem) {
          if (this.sortField.includes('.')) {
            // If the sortField prop is nested (schedule.createdAt)
            query = query.startAfter(this.lastItem[this.sortField.split('.')[0]][this.sortField.split('.')[1]])
          } else {
            // If the sortField prop is not nested (createdAt)
            query = query.startAfter(this.lastItem[this.sortField])
          }
        }
      } else {
        if (this.firstItems[page]) {
          if (this.sortField.includes('.')) {
            // If the sortField prop is nested (schedule.createdAt)
            query = query.startAt(this.firstItems[page][this.sortField.split('.')[0]][this.sortField.split('.')[1]])
          } else {
            // If the sortField prop is not nested (createdAt)
            query = query.startAt(this.firstItems[page][this.sortField])
          }
        }
      }

      this.page = page

      return this.$bind('items', query).then(data => {
        this.loading = false
        if (data) {
          this.firstItems[page] = data[0]
          this.lastItem = data[data.length - 1]
        }
        return data
      })
    },
    pageCount(value) {
      if (value !== this.perPage) {
        this.loadItems(this.page)
        this.perPage = value
      }
    }
  }
}
</script>
