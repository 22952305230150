<template>
  <dashboard-content full-width>
    <page-header sticky>
      <h1 class="mb-4">
        Notifications
      </h1>
    </page-header>
    <v-row>
      <v-col class="col-12 py-0">
        <v-select
          v-model="filterValue"
          :items="filterItems"
          label="Filtering by Status"
          name="send-status"
          @change="filterChangeHandler($event, 'send-status')"
        />

        <firebase-table
          :key="key"
          :headers="headers"
          :filters="filters"
          collection="times"
          sort-field="sendTime"
        >
          <template v-slot:item="{ item }">
            <tr :style="{ 'background-color': timeRowColor(item) }">
              <td>
                {{ formatDatetimeLocal(item.sendTime) }}
              </td>
              <td>
                <template v-if="item.sentTime">
                  {{ formatDatetimeLocal(item.sentTime) }}
                </template>
              </td>
              <td>
                {{ getStatusName(item.sendStatus) }}
              </td>
              <td>
                {{ getActivityStatus(item, 'pageOpened') }}
              </td>
              <td>
                {{ getActivityStatus(item, 'started') }}
              </td>
              <td>
                {{ getActivityStatus(item, 'progress') }}
              </td>
              <td>
                {{ getActivityStatus(item, 'complete') }}
              </td>
              <td>
                {{ item.errorMessage }}
              </td>
              <td>
                <v-icon
                  small
                  class="mr-2"
                  @click="showItem(item.id)"
                >
                  {{ mdiEye }}
                </v-icon>
              </td>
            </tr>
          </template>
        </firebase-table>
      </v-col>
    </v-row>
  </dashboard-content>
</template>

<script>
import { mdiEye } from '@mdi/js'
import moment from '@/plugins/moment'
import DashboardContent from '@/components/dashboard/DashboardContent'
import FirebaseTable from '@/components/FirebaseTable'
import getActivityStatus from '@/kit/helpers/activityStatus'
import PageHeader from '@/components/dashboard/PageHeader.vue'
import {
  SENT,
  getStatusName,
  getStatusId
} from '@/kit/dictionaries/timesSendStatus'

export default {
  components: { DashboardContent, FirebaseTable, PageHeader },
  data() {
    return {
      mdiEye,
      getStatusName,
      getActivityStatus,
      headers: [
        { text: 'Send time', sortable: false },
        { text: 'Sent time', sortable: false },
        { text: 'Status', sortable: false },
        { text: 'Page opened, qt', sortable: false },
        { text: 'Activity started', sortable: false },
        { text: 'Activity progress, %', sortable: false },
        { text: 'Activity complete', sortable: false },
        { text: 'Error message', sortable: false },
        { text: 'Actions', sortable: false }
      ],
      filterValue: '',
      filters: {},
      filterItems: [
        { text: 'All', value: '' },
        { text: 'Planned', value: 'planned' },
        { text: 'To send', value: 'to_send' },
        { text: 'Sent', value: 'sent' },
        { text: 'Failed', value: 'failed' }
      ],
      key: 1
    }
  },
  mounted() {
    Object.keys(this.$route.query).forEach(item => {
      const key = this.toCamelCase(item)

      this.filterValue = this.$route.query[item]
      this.filters = {
        ...this.filters,
        [key]: getStatusId(this.$route.query[item].toUpperCase())
      }
    })
  },
  methods: {
    filterChangeHandler(value, type) {
      const key = this.toCamelCase(type)

      this.filters =
        value !== ''
          ? {
              ...this.filters,
              [key]: getStatusId(value.toUpperCase())
            }
          : {}

      this.key++
    },
    toCamelCase(value) {
      return value.replace(/([-_][a-z])/gi, m =>
        m.toUpperCase().replace('-', '')
      )
    },
    timeRowColor(time) {
      if (moment(time.sendTime.toDate()) < moment()) {
        return time.sendStatus === SENT ? '#E8F5E9' : '#FFEBEE'
      }
    },
    showItem(id) {
      this.$router.push({ name: 'notificationShow', params: { id } })
    }
  }
}
</script>
